<template>
  <main v-if="entity">
    <div class="order-title info-title">
      金额信息
    </div>
    <ul class="order-ul">
      <li>
        <div class="order-date">
          总金额（原价）：
          <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
            <span v-if="entity.totalPrice">￥{{Number(entity.totalPrice).toFixed(2)}}</span>
            <a class="click-add-info" @click="toClickAddInfo">合并到购物车</a>
          </template>
          <a v-else href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"><span style="color: #444">咨询客服</span></a>
        </div>
      </li>
    </ul>
    <div class="goods-info-wapper">
      <div class="goods-wapper">
        <div class="info-title" >
          商品信息
        </div>
        <ul class="product-module">
          <li class="product-li" v-for="(item, index) in list" :key="index">
            <template v-if="item.typeId ==0">
                <router-link :to="{ name: 'productdetail', params: { id: item.pid, classId: 0} }">
                <div class="add-car" style="right: 0px; width: 40.5px;">
                  <span>X {{ item.qty }}</span>
                </div>
                <div class="product-title">{{ item.title.replace('‘','\'').replace('’','\'') }}</div>
                <div class="product-info">
                  <p v-if="item.spec">
                    <span>{{ item.itemNo }}</span>
                    , &nbsp;
                    <span>{{ item.spec }}</span>
                  </p>
                  <p v-else>
                    <span>{{ item.itemNo }}</span>
                  </p>
                </div>

                <div class="product-brand">
                  <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                    <span class="product-price">￥{{Number(item.price).toFixed(2) || ''}}</span>
                  </template>
                  <span v-else style="color: transparent">X {{ item.qty }}</span>
                </div>
              </router-link>
            </template>
            <template v-else>
              <router-link :to="{ name: 'bnccdetail', params: { id: item.pid, classId: 0} }">
                <div class="add-car" style="right: 0px; width: 40.5px;">
                  <span>X {{ item.qty }}</span>
                </div>
                <div class="product-title">{{ item.title.replace('‘','\'').replace('’','\'') }}</div>
                <div class="product-info">
                  <p v-if="item.spec">
                    <span>BNCC{{item.pid}}</span>
                    , &nbsp;
                    <span>{{ item.spec }}</span>
                  </p>
                  <p v-else>
                    <span>{{ item.itemNo }}</span>
                  </p>
                </div>

                <div class="product-brand">
                  <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                    <span class="product-price">￥{{Number(item.price).toFixed(2) || ''}}</span>
                  </template>
                  <span v-else style="color: transparent">X {{ item.qty }}</span>
                </div>
              </router-link>
            </template>

          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref, inject } from "vue";
  import { useRoute } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import dateFormat from "@/utils/dateFormat";
  import router from "@/router";
  import qs from "qs";
  import Modal from "@/components/UI/Modal";
  export default defineComponent({
    name: "virtuallyInfo",
    setup() {
      const userInfo = inject("userInfo") as any;
      const biaowuPriceOpen = ref()
      const entity = ref(null);
      const list = ref([]);
      const route = useRoute();
      const id = route.query.id;
      axios
        .get("/M/User/virtuallyInfoShow/" + id, { params: { validate: true } })
        .then((res) => {
          if (res.data.success) {
            entity.value = res.data.obj.orderVO;
            list.value = res.data.obj.list;
            biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
          } else {
            if(res.data.msg = 'NotLogin'){
              Toast({
                type: "warning",
                title: "请先登录！",
              });
              router.push({
                name:'login'
              })
              return false;
            }else{
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
      function toClickAddInfo() {
        Modal({
          title: "合并信息",
          content: "确定合并产品信息到购物车中吗？",
          confirmText: "确定",
          closeText: "取消",
          onConfirm: () => {
            const data = qs.stringify({
              id:id,
            });
            axios.post("/M/User/clickAddInfo", data,{})
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                  });
                  //跳转购物车页面
                  router.push({
                    name:'ShopCar'
                  });
                  return false;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                Toast({
                  type: "error",
                  title: err.message || err
                });
              });
          }
        });
      }
      return {
        entity,
        dateFormat,
        list,
        biaowuPriceOpen,
        userInfo,
        toClickAddInfo
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 109.987px;
    width: 355.013px;
    margin: 0 auto;
  }

  /**

      菜单导航
    */
  .menu-nav {
    overflow: hidden;
    height: 45px;
    line-height: 45px;
    border-bottom: 6.487px solid #f4f4f4;
  }

  .menu-nav li {
    float: left;
    width: 50%;
    text-align: center;
  }
  .menu-nav li a {
    display: block;
    text-align: center;
    line-height: 45px;
    width: 100%;
    font-size: 16.012px;
    color: #666;
  }
  .menu-nav .active a {
    color: #df0024;
  }

  .order-ul li {
    box-sizing: border-box;
    padding: 0 15px;

    overflow: hidden;

    position: relative;
  }

  .order-ul .title {
    width: 100%;
    height: 40.012px;
    line-height: 40.012px;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    color: #444;
    position: relative;
  }
  .price {
    position: absolute;
    right: 0;
    top: 0;
    height: 40.012px;
    line-height: 40.012px;
    color: rgb(102, 102, 102);
  }
  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 10.013px;

    font-size: 15px;
    box-sizing: border-box;
    color: #666;
    position: relative;
  }
  .order-btn {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.25px;
    text-align: center;
    width: 79.987px;
    height: 22.5px;
    border-radius: 15px;

    line-height: 22.5px;

    font-size: 13.988px;
    color: #df0034;
    border: solid 1px #df0024;
  }

  .balance {
    position: fixed;
    color: #666;
    bottom: 49.987px;
    left: 0;
    right: 0;
    height: 49.987px;
    line-height: 49.987px;
    padding-left: 10.013px;
    border-top: 1.012px solid #f4f4f4;
    border-bottom: 1.012px solid #f4f4f4;
    font-size: 18px;
    font-weight: bold;
    background-color: #fff;
    z-index: 100;
  }

  .empty {
    padding-top: 160.013px;
    text-align: center;
    padding-bottom: 49.987px;

    color: #9f9f9f;
    font-size: 13.012px;
  }
  .empty img {
    width: 132.487px;
    height: 103.988px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .empty a {
    font-size: 13.012px;
    color: #df0024;
  }

  .order-title {
    color: #52ba65;
    padding-left: 15px;
    font-size: 18px;
  }
  .goods-wapper,
  .logistics-info-wapper {
    width: 355.013px;
    margin: 0 auto;
  }

  .info-title {
    color: #444444;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;

    position: relative;
    padding-left: 7px;
    .expend {
      width: 16px;
      height: auto;
      vertical-align: middle;
      transform: rotateZ(0);
      transition: transform 0.5s;
    }
    .expended {
      transform: rotateZ(-180deg);
      transition: transform 0.5s;
    }
  }
  .info-title::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 3px;
    height: 14px;
    transform: translateY(-50%);
    background-color: #aa183c;
  }
  .goods-info-wapper img {
    width: 90px;
    height: 90px;
    vertical-align: top;
  }
  .good-info {
    display: inline-block;
    padding-top: 4.988px;
    width: 250.012px;
  }
  .good-info p {
    color: #444444;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 4.988px;
    margin: 0;
  }
  .logistics-info-wapper p {
    color: #444444;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 4.988px;
    margin: 0;
    margin-bottom: 15px;
  }
  .logistics {
    position: relative;
  }
  .logistics a {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 85.013px;
    height: 28.012px;
    border-radius: 13.988px;
    border: solid 1px #cccccc;
    line-height: 28.012px;
    text-align: center;
    color: #666666;
    font-size: 15px;
  }

  /* 产品列表 */

  .product-module .product-li {
    width: 100%;
    padding: 0.4rem 0.267rem;
    box-sizing: border-box;
    font-size: 0.347rem;
    color: #999;
    /*padding-left: 2.267rem;*/
    position: relative;
    border-bottom: 0.027rem solid #f4f4f4;
    position: relative;
  }

  .product-module .product-img {
    position: absolute;
    left: 0.267rem;
    top: 0.5rem;
  }

  .product-module .product-img img {
    width: 1.733rem;
    height: 1.733rem;
    object-fit: cover;
  }

  .product-module .product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.427rem;
    color: #333;
    line-height: 1.5;
    min-height: 0.587rem;
    padding-right: 1.13rem;
    box-sizing: border-box;
    margin-bottom: 0.133rem;
    position: relative;
    margin-top: 0.05rem;
  }

  .product-module .product-info {
    // font-size: 0.373rem;
    font-size: 12px;
    color: #999;
    line-height: 0.427rem;
    margin-bottom: 0.2rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .product-module .product-brand {
    color: #999;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-module .product-brand .product-price {
    color: #df0024;
    font-size: 12px;
  }

  .product-module .product-brand span {
    padding-right: 0.133rem;
    font-size: 0.373rem;
    color: #999;
    line-height: 0.427rem;
  }

  .product-module .add-car {
    position: absolute;
    right: 0.613rem;
    // top: 0.5rem;
    top: 0.57rem;
    width: 0.44rem;
    height: 0.413rem;
    z-index: 9;
    // padding: 0.267rem;
    padding-top: 0;
    padding-right: 0.267rem;
    text-align: right;
    color: #df0024;
  }

  .product-module .add-car img {
    width: 100%;
    height: 100%;
  }
  .click-add-info{
    display: inline-block;
    margin-left: 20px;
    width:100px;
    height: 0.6rem;
    text-align: center;
    line-height: 20px;
    font-size: 0.37301rem;
    color: #df0034;
    border: solid 0.02667rem #df0024;
    border-radius: 0.53333rem;
  }
</style>
